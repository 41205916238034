import { render, staticRenderFns } from "./OrgAccount.vue?vue&type=template&id=60fa99b0&scoped=true"
import script from "./OrgAccount.vue?vue&type=script&lang=js"
export * from "./OrgAccount.vue?vue&type=script&lang=js"
import style0 from "./OrgAccount.vue?vue&type=style&index=0&id=60fa99b0&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "60fa99b0",
  null
  
)

export default component.exports