<template>
<div class="container border-container">
    <div class="search-container">
        <span class="list-title">机构账号</span>
        <el-button type="primary" icon="el-icon-plus" @click="handleDialogShow('new')" v-if="auth('org.account.save', 'ADMIN')">新增机构账号</el-button>
    </div>
    <div class="table-container">
        <el-table :data="accountList" tooltip-effect="dark" style="width: 100%" size="medium"
            :header-cell-style="{height: '40px', background: 'linear-gradient(180deg,rgba(251,252,253,1) 0%,rgba(246,248,250,1) 100%)'}"
            :cell-style="{height: '50px',padding: '5px 0'}" header-align="center">
            <el-table-column prop="name" label="用户名" min-width="120" align="center">
                <template slot-scope="scope">
                    <span :class="scope.row.type ? 'fz-bold' : ''">{{scope.row.user.name}}</span>
                </template>
            </el-table-column>
            <el-table-column prop="phone" label="手机号" min-width="120" align="center">
                <template slot-scope="scope">
                    {{scope.row.user.phone}}
                </template>
            </el-table-column>
            <el-table-column prop="role" label="角色" min-width="120" align="center">
                <template slot-scope="scope">
                    {{scope.row.role_name || '-'}}
                </template>
            </el-table-column>
            <el-table-column prop="create_time" label="创建时间" min-width="180" align="center">
                <template slot-scope="scope">
                    {{scope.row.create_time | timeFilter}}
                </template>
            </el-table-column>
            <el-table-column label="操作" min-width="100" align="center" v-if="auth('org.account.save')">
                <template slot-scope="scope">
                    <el-button type="text" class="icon-btn" @click="handleDialogShow('edit', scope.row)">
                        <el-tooltip placement="top" content="修改账号信息">
                            <i class="iconfont icon-edit" />
                        </el-tooltip>
                    </el-button>
                    <el-button type="text" class="icon-btn" @click="handleDialogShow('pass', scope.row)">
                        <el-tooltip placement="top" content="修改账号密码">
                            <i class="iconfont icon-lock" />
                        </el-tooltip>
                    </el-button>
                    <el-button type="text" class="icon-btn" @click="handleDeleteAccount(scope.row)">
                        <el-tooltip placement="top" content="删除账号">
                            <i class="iconfont icon-delete" />
                        </el-tooltip>
                    </el-button>
                </template>
            </el-table-column>
            <div class="" slot="empty">
                <no-data></no-data>
            </div>
        </el-table>
    </div>
    <div class="page-container">
        <el-pagination layout="total, sizes, prev, pager, next" background
            @size-change="pageSizeChange"
            @current-change="pageChange"
            :current-page="currentPage"
            :page-sizes="[total, 10, 15, 20, 25]"
            :total="total" :page-size="pageSize">
        </el-pagination>
    </div>
    <div class="dialog-container">
        <el-dialog class="org-dialog" :title="dialogTitle" width="560px" :visible.sync="dialogVisible" :before-close="handleDialogClose">
            <div class="dialog-content" v-if="dialogType !== 'pass'">
                <div class="form-item">
                    <span class="key">用户名:</span>
                    <el-input class="value" v-model="editForm.name" placeholder="请输入用户名"></el-input>
                </div>
                <div class="form-item" v-if="dialogType === 'new'">
                    <span class="key">手机号:</span>
                    <el-input class="value" v-model="editForm.phone" placeholder="请输入手机号"></el-input>
                </div>
                <div class="form-item"  v-if="dialogType === 'new'">
                    <span class="key">密码:</span>
                    <el-input class="value" v-model="editForm.password"  placeholder="请输入密码" type="password" show-password ></el-input>
                </div>
                <div class="form-item" style="height: 32px;">
                    <span class="key">机构管理员:</span>
                    <el-radio-group class="value" v-model="editForm.type">
                        <el-radio :label="0">否</el-radio>
                        <el-radio :label="1">是</el-radio>
                    </el-radio-group>
                </div>
            </div>
            <div class="dialog-content" v-else>
                <div class="form-item">
                    <span class="key">密码:</span>
                    <el-input class="value" v-model="editForm.password"  placeholder="请输入密码" type="password" show-password ></el-input>
                </div>
            </div>
            <span slot="footer" class="dialog-footer" style="display:flex;justify-content: center;">
                <el-button @click="handleDialogClose">取 消</el-button>
                <el-button type="primary" @click="handleEditConfirm">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</div>
</template>

<script>
import Core from '@/core';
const USER_TYPE = Core.Const.USER_TYPE
export default {
    components: {
        NoData: () => import('@/components/Empty.vue')
    },
    props: {
        orgId: {
            type: [Number,String]
        },
    },
    data() {
        return {
            userType: Core.Data.getUserType(),
            USER_TYPE,
            accountType: '',
            total: 0,
            currentPage: 1,
            pageSize: 10,
            accountList: [],
            authRoleList: [],

            dialogVisible: false,
            dialogTitle: '',
            dialogType: '',

            editForm:{
                id: '',
                name: '',
                phone: '',
                password: '',
                role: '',
                type: '',
            },
        };
    },
    created() {
        this.getAccountList();
    },
    mounted() {
    },
    computed: {
    },
    methods: {
        auth: Core.Utils.auth,
        pageChange(page) { // 页面改变
            this.currentPage = page;
            this.getAccountList();
        },
        pageSizeChange(size) {
            this.pageSize = size;
            this.getAccountList();
        },
        routerChange(key, q = '') { // 路由改变
            switch (key) {
                case 1:
                break;
            }
        },

        getAccountList() { // 获取 机构账号 列表
            Core.Api.OrgAccount.list(
                this.orgId || 0,
                this.currentPage,
                this.pageSize,
            ).then((res) => {
                console.log("getAccountList -> res", res)
                this.total = res.count
                this.accountList = res.list
            });
        },

        handleDeleteAccount(item) { // 删除 机构账号
            this.$confirm('确定要删除这个机构账号吗?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                console.log('item', item);
                Core.Api.OrgAccount.delete(item.id, item.user_id).then(res => {
                    this.$message.success('删除成功!');
                    this.getAccountList(1)
                });
            })
        },
        handleDialogShow(type, item = {}) { // 显示 弹框
            console.log("handleDialogShow -> item", item)
            this.dialogType = type
            console.log("this.dialogType", this.dialogType)
            switch (type) {
                case 'new':
                    this.dialogTitle = '新增机构账号';
                    break;
                case 'edit':
                    this.dialogTitle = '修改账号';
                    this.editForm.id = item.id;
                    this.editForm.name = item.user.name;
                    this.editForm.phone = item.user.phone;
                    this.editForm.role = item.role_id;
                    this.editForm.type = item.type;
                    break;
                case 'pass':
                    this.dialogTitle = '修改账号密码';
                    this.editForm.id = item.id;
                    break;
            }
            this.dialogVisible = true
        },
        handleDialogClose() { // 关闭 弹框
            this.dialogVisible = false
            Object.assign(this.$data.editForm, this.$options.data().editForm)
        },
        handleEditConfirm(item) { // 确定 编辑
        console.log("item", item)
            let form = this.editForm
            if (this.dialogType === 'new') {
                if (!form.password) {
                    return this.$message.warning('请输入密码')
                } else if (!form.phone) {
                    return this.$message.warning('请输入手机号')
                } else if ( !(/^1[3456789]\d{9}$/.test(form.phone)) ) {
                    return this.$message.warning('请正确填写手机号')
                }
            }
            switch (this.dialogType) {
                case 'new':
                case 'edit':
                    if (!form.name) {
                        return this.$message.warning('请输入用户名')
                    }
                    Core.Api.OrgAccount.save(
                        this.orgId,
                        form.id || 0,
                        form.name,
                        form.phone,
                        form.password,
                        form.role, // 0
                        form.type, // 0
                        form.name, // account
                    ).then(res => {
                        this.$message.success('保存成功')
                        this.handleDialogClose();
                        this.getAccountList();
                    }).catch(err => {
                        console.log("handleEditConfirm -> err", err)
                    })
                    break;
                case 'pass':
                    if (!form.password) {
                        return this.$message.warning('请输入密码')
                    }
                    Core.Api.OrgAccount.passwordUpdate(
                        this.editForm.id,
                        form.password,
                    ).then(res => {
                        console.log("handleEditConfirm -> res", res)
                        this.$message.success('修改成功')
                        this.handleDialogClose();
                        this.getAccountList();
                    }).catch(err => {
                        console.log("handleEditConfirm -> err", err)
                    })
                    break;
            }
        }
    }
};
</script>
<style lang="scss" scoped>
.container {
    @import '@/common/styles/table.scss';
    .search-container {
        @include flex(row, space-between, center);
    }
    .form-item {
        display: flex;
        align-items: center;
        width: 100%;
        box-sizing: border-box;
        padding: 0 10px;
        + .form-item {
            margin-top: 16px;
        }
        .key {
            width: 100px;
        }
        .value {
            width: calc(100% - 100px);
        }
    }
}
</style>